@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-editor {
  @apply text-gray-800 min-h-[200px] transition duration-200 ease-in py-4 px-3;
  font-family: "Arial", sans-serif;
  line-height: 1.5;
}

.ql-editor p {
  @apply text-sm font-normal mb-0.5;
}

.ql-editor ul {
  @apply list-disc list-outside ml-5 mt-4;
}

.ql-editor ol {
  @apply list-decimal list-outside ml-5 mt-4;
}

.ql-editor li {
  @apply my-1 text-sm;
}

.ql-editor h1 {
  @apply text-xl leading-7 font-bold mb-2;
}

.ql-editor h2 {
  @apply text-lg leading-6 font-bold mb-2;
}

.ql-editor h3 {
  @apply text-base leading-5 font-bold mb-2;
}

.ql-editor h4 {
  @apply text-sm leading-5 font-semibold mb-2;
}

.ql-editor h5 {
  @apply text-sm leading-5 font-medium mb-2;
}

.ql-editor h6 {
  @apply text-sm leading-5 font-medium mb-2;
}

.quill {
  @apply rounded-md overflow-hidden;
}

.ql-toolbar {
  @apply bg-gray-100 text-gray-700 rounded-t-md border-b border-gray-300 sticky top-0 z-10 p-1;
}

.ql-toolbar .ql-picker {
  @apply text-sm;
}

.ql-toolbar button {
  @apply hover:bg-gray-200 rounded p-1;
}

.ql-header {
  @apply text-gray-700 p-1;
}

.ql-container {
  @apply flex-1 rounded-b-md px-3 border-0;
  background-color: #ffffff;
}

.ql-snow.ql-toolbar .ql-formats {
  @apply mr-2;
}
