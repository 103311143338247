@tailwind base;
@tailwind components;
@tailwind utilities;

.highlighted {
  background-color: yellow;
  font-weight: bold;
}

.lato {
  font-family: "Lato", sans-serif;
}

.times {
  font-family: "Times New Roman", Times, serif;
}

.drop-target {
  @apply bg-gray-100 animate-pulse transition duration-200 ease-in;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  height: 300px;
  width: 100%;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

/* NAV ---------------------------------------------------------------------------------------------------- */
.nav-link {
  @apply h-16 xl:h-20  xl:px-4 pt-5 flex justify-start flex-col px-0 mx-2 border-b-2 hover:text-white font-bold tracking-wide text-white text-lg lg:text-sm xl:text-base 2xl:text-lg transition ease-in duration-75;
}

/* Smart Table ---------------------------------------------------------------------------------------------------- */
.smart-table-cell {
  @apply px-2 max-w-sm text-left;
}

/* Titles */

.crm-title {
  @apply text-xl font-semibold text-gray-900  w-max;
}

.custom-scrollbar-thin::-webkit-scrollbar {
  @apply h-1 w-1 rounded-full;
}
.custom-scrollbar-thick::-webkit-scrollbar {
  @apply h-2 w-2;
}

.custom-scrollbar-blue::-webkit-scrollbar-thumb {
  @apply bg-blue-400 hover:bg-blue-500 rounded-full;
}
.custom-scrollbar-gray::-webkit-scrollbar-thumb {
  @apply bg-gray-400 hover:bg-gray-500 rounded-full opacity-80;
}

.custom-scrollbar-gray::-webkit-scrollbar-track {
  background: transparent; /* Make track background transparent */
}
.codex-editor__redactor {
  padding-bottom: 50px !important;
}

/* Markdown --------------------------------- */

.markdown {
  @apply text-gray-600;
  font-family: "Roboto", sans-serif;
}

.markdown-preview {
  max-height: 800px;
}

.markdown h1 {
  @apply mt-2 mb-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl;
}

.markdown h2 {
  @apply text-gray-800 mt-6 mb-5;
}

.markdown h3 {
  @apply text-gray-800 mt-6;
}

.markdown h4 {
  @apply block text-base text-center text-c-orange-abundo font-semibold tracking-wide uppercase;
}

.markdown h6 {
  @apply mt-8 text-xl text-gray-600 leading-8;
}

.markdown p {
  @apply mb-5;
}

.markdown strong {
  @apply text-gray-800;
}

.markdown ul {
  @apply list-disc mb-5 pl-5;
}

.markdown ol {
  @apply list-decimal mb-5 pl-5;
}

.markdown li p {
  @apply my-1;
}

.markdown a {
  @apply text-c-hyperlink underline;
}

.markdown blockquote {
  @apply ml-5 pl-2 border-l-4 border-gray-200;
}

.markdown img {
  @apply mx-auto rounded-md;
}

/* Markdown Blog--------------------------------- */

.markdown-blog {
  @apply text-gray-800 font-lato;
}

.markdown-preview {
  max-height: 800px;
}

.markdown-blog h1 {
  @apply mt-2 mb-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl;
}

.markdown-blog h2 {
  @apply text-gray-800 mt-6 mb-5;
}

.markdown-blog h3 {
  @apply text-gray-800 mt-6;
}

.markdown-blog h4 {
  @apply block text-base text-center text-c-orange-abundo font-semibold tracking-wide uppercase;
}

.markdown-blog h6 {
  @apply mt-8 text-xl text-gray-600 leading-8;
}

.markdown-blog p {
  @apply mb-5;
}

.markdown-blog strong {
  @apply text-gray-800;
}

.markdown-blog ul {
  @apply list-disc  pl-5 space-y-2 mb-5;
}

.markdown-blog ol {
  @apply list-decimal  pl-5 space-y-2 mb-5;
}

.markdown-blog li p {
  @apply my-1;
}

.markdown-blog a {
  @apply text-c-hyperlink underline;
}

.markdown-blog blockquote {
  @apply ml-5 pl-2 border-l-4 border-gray-200;
}

.markdown-blog img {
  @apply mx-auto rounded-md;
}

/* Markdown parabot */
.markdown-parabot {
  @apply space-y-1 text-left;
  font-family: "Roboto", sans-serif;
}

.markdown-parabot-preview {
  max-height: 800px;
}

.markdown-parabot h1 {
  @apply block text-3xl  sm:text-4xl font-semibold mt-4;
}

.markdown-parabot h2 {
  @apply text-2xl sm:text-3xl font-semibold mt-3;
}

.markdown-parabot h3 {
  @apply text-xl sm:text-2xl font-semibold mt-3;
}

.markdown-parabot h4 {
  @apply block text-lg sm:text-xl mt-3;
}
.markdown-parabot h5 {
  @apply block text-base  sm:text-lg mt-3;
}

.markdown-parabot h6 {
  @apply text-base  leading-8;
}

.markdown-parabot p {
  @apply text-left;
}

.markdown-parabot strong {
  @apply font-bold;
}

.markdown-parabot ul {
  @apply list-disc  mt-2 pl-5 text-left;
}

.markdown-parabot ol {
  @apply list-decimal   pl-5 text-left;
}

.markdown-parabot li p {
  @apply text-base;
}

.markdown-parabot a {
  @apply text-c-hyperlink underline;
}

.markdown-parabot blockquote {
  @apply ml-5 pl-2 border-l-4 border-gray-200;
}

/* Markdown notes */
.markdown-notes {
  @apply space-y-1 text-left;
  font-family: "Roboto", sans-serif;
}

.markdown-notes-preview {
  max-height: 800px;
}

.markdown-notes h1 {
  @apply block text-3xl  leading-8 font-extrabold tracking-tight  sm:text-4xl;
}

.markdown-notes h2 {
  @apply text-2xl;
}

.markdown-notes h3 {
  @apply text-xl;
}

.markdown-notes h4 {
  @apply block text-base font-semibold tracking-wide uppercase;
}

.markdown-notes h6 {
  @apply text-xl  leading-8;
}

.markdown-notes p {
  @apply text-left py-1;
}

.markdown-notes strong {
  @apply font-bold;
}

.markdown-notes ul {
  @apply list-disc  pl-5 text-left;
}

.markdown-notes ol {
  @apply list-decimal  pl-5 text-left;
}

.markdown-notes li p {
  @apply my-1;
}

.markdown-notes a {
  @apply text-c-hyperlink underline;
}

.markdown-notes blockquote {
  @apply ml-5 pl-2 border-l-4 border-gray-200;
}

/* Markdown Big Picture */

.big-picture-text {
  @apply text-gray-700 text-xl font-semibold;
  font-family: "Roboto", sans-serif;
}

.big-picture-text h1 {
  @apply mt-2 mb-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl;
}

.big-picture-text h2 {
  @apply text-gray-800 mt-6 mb-5;
}

.big-picture-text h3 {
  @apply text-gray-800 mt-6;
}

.big-picture-text h4 {
  @apply block text-base text-center text-c-orange-abundo font-semibold tracking-wide uppercase;
}

.big-picture-text h6 {
  @apply mt-8 text-xl text-gray-600 leading-8;
}

.big-picture-text p {
  @apply text-lg;
}

.big-picture-text strong {
  @apply text-gray-800;
}

.big-picture-text ul {
  @apply list-disc mb-5 pl-5;
}

.big-picture-text ol {
  @apply list-decimal mb-5 pl-5;
}

.big-picture-text li p {
  @apply my-1;
}

/* Markdown Big Picture */

.client-portal-task {
  @apply text-base sm:text-xl;
  font-family: "Roboto", sans-serif;
}

.client-portal-task h1 {
  @apply mt-2 mb-8 block text-3xl text-center leading-8 font-extrabold tracking-tight sm:text-4xl;
}

.client-portal-task h2 {
  @apply mt-6 mb-5;
}

.client-portal-task h3 {
  @apply mt-6;
}

.client-portal-task h4 {
  @apply block text-base text-center  font-semibold tracking-wide uppercase;
}

.client-portal-task h6 {
  @apply mt-8 text-xl leading-8;
}

.client-portal-task p {
  @apply text-base lg:text-lg;
}

.client-portal-task strong {
  @apply font-bold;
}

.client-portal-task ul {
  @apply list-disc mb-5 pl-5;
}

.client-portal-task ol {
  @apply list-decimal mb-5 pl-5;
}

.client-portal-task li p {
  @apply my-1;
}

.client-portal-task a {
  @apply text-c-hyperlink underline;
}

/* Markdown CHAT */
.markdown-chat {
  @apply space-y-2 leading-relaxed font-sans;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.6;
  font-weight: 450;
}

.markdown-chat-preview {
  max-height: 800px;
}
.codex-editor__redactor {
  padding-bottom: 50px !important;
}

.markdown-chat h1 {
  @apply text-lg sm:text-xl font-semibold mb-1 mt-2 leading-tight;
}

.markdown-chat h2 {
  @apply text-base sm:text-lg font-semibold mb-1 mt-2 leading-tight;
}

.markdown-chat h3 {
  @apply text-base sm:text-lg font-medium mb-2 mt-3;
}

.markdown-chat h4 {
  @apply text-sm sm:text-base font-medium mb-1 mt-3;
}

.markdown-chat h5 {
  @apply text-sm font-medium mb-1 mt-2;
}

.markdown-chat h6 {
  @apply text-xs font-medium mb-1 mt-2 uppercase tracking-wide;
}

.markdown-chat p {
  @apply mb-4 leading-relaxed;
}

.markdown-chat strong {
  @apply font-bold;
}

.markdown-chat em {
  @apply italic;
}

.markdown-chat ul {
  @apply list-disc pl-6 mb-4 space-y-2;
}

.markdown-chat ol {
  @apply list-decimal pl-6 mb-4 space-y-2;
}

.markdown-chat li {
  @apply mb-1;
}

.markdown-chat li p {
  @apply my-1;
}

.markdown-chat a {
  @apply underline decoration-1 underline-offset-2 transition-colors;
}

.markdown-chat blockquote {
  @apply pl-4 border-l-4 border-gray-200 italic my-4 py-1;
}

.markdown-chat code {
  @apply font-mono text-sm px-1.5 py-0.5 bg-gray-100 rounded;
}

.markdown-chat pre {
  @apply border rounded-lg p-4  overflow-x-auto my-4 text-sm;
}

.markdown-chat pre code {
  @apply bg-transparent p-0 block text-gray-800;
}

.markdown-chat table {
  @apply w-full border-collapse my-4 rounded-lg overflow-hidden;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.markdown-chat th {
  @apply bg-gray-100 font-bold p-3 text-left border-b-2 border-gray-200;
}

.markdown-chat td {
  @apply border border-gray-200 p-3 text-left;
}

.markdown-chat tr:nth-child(even) {
  @apply bg-gray-50;
}

.markdown-chat img {
  @apply max-w-full h-auto rounded-md my-4;
}

.markdown-chat hr {
  @apply my-6 border-t border-gray-200;
}

/* CONTAINERS ------------------------------------------------------------------------------------------------ */
.t-container-top {
  @apply mx-10 pt-32;
}
.container {
  @apply px-4 sm:px-6 lg:px-8;
}
.t-container {
  @apply mx-10;
}

/* Inputs */
/* checkbox */
.checkbox-md {
  @apply h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500;
}
.checkbox-lg {
  @apply h-6 w-6 rounded border-gray-300 text-blue-600 focus:ring-blue-500;
}

/* Hover Edit */

.edit-hover {
  @apply border-b border-t-0 border-x-0  border-gray-300 border-dashed focus:border-solid   focus:border focus:border-primary-500 focus:rounded-lg  px-2 focus:ring-0 focus:ring-transparent;
}

/* BUTTONS ------------------------------------------------------------------------------------------------ */

/* BUTTON BASES */
.btn {
  @apply items-center text-center tracking-wider border border-transparent transition duration-100  hover:shadow-md shadow-sm text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-transparent;
}
/* 
.btn-md {
  @apply px-7 py-3.5 text-base;
}

.btn-lg {
  @apply px-10 py-4 text-xl;
} */

.butn {
  @apply w-56 h-16 text-lg font-extrabold font-sans  tracking-wide rounded bg-c-teal-500;
}

.butn:hover {
  @apply transition duration-200 ease-in brightness-105 shadow-lg;
}

.butn-sharp {
  @apply w-56 h-16 text-lg font-extrabold font-sans  tracking-wide;
}

.butn-wide {
  @apply py-4 px-8 sm:px-10 min-w-min hover:brightness-110 hover:shadow-lg  duration-200 ease-in sm:min-w-max h-full text-sm sm:text-lg font-extrabold font-sans  tracking-wide rounded;
}

.butn-sm {
  @apply min-w-min sm:min-w-max h-full px-3 xs:px-5 py-1 hover:shadow-md transition duration-200 ease-in text-sm sm:text-base xs:text-lg font-extrabold font-sans  tracking-wide rounded;
}

.butn-xl {
  @apply py-8 px-10 lg:px-36 sm:min-w-max h-full text-sm sm:text-lg font-extrabold font-sans  tracking-wide rounded;
}

/* Tailwind Buttons */
/* colors */
.btn-primary {
  @apply inline-flex items-center  rounded-md border border-transparent bg-blue-600  font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2;
}
.btn-primary-rounded {
  @apply inline-flex items-center  rounded-full border border-transparent bg-blue-600  font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2;
}
.btn-primary-outline-rounded {
  @apply inline-flex items-center  rounded-full border border-blue-600  font-medium text-blue-600 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2;
}
.btn-dark-blue {
  @apply inline-flex items-center  rounded-md border border-transparent bg-primary-800  font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2;
}
.btn-primary-soft {
  @apply transition duration-100 ease-in inline-flex items-center  rounded-md   bg-primary-200  font-semibold tracking-normal text-primary-600 shadow-sm hover:bg-primary-300 focus:outline-none;
}
.btn-primary-outline {
  @apply inline-flex items-center rounded-md border border-blue-600 font-bold bg-transparent text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2;
}
.btn-secondary {
  @apply inline-flex items-center  rounded-md border border-transparent bg-secondary-500  font-medium text-white shadow-sm hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2;
}
.btn-secondary-rounded {
  @apply inline-flex items-center  rounded-full border border-transparent bg-secondary-500  font-medium text-white shadow-sm hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2;
}
.btn-secondary-outline {
  @apply inline-flex items-center rounded-md border border-secondary-500 bg-transparent font-medium  text-secondary-500 hover:text-white hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2;
}

.btn-gray {
  @apply inline-flex items-center rounded-md border border-transparent bg-gray-500  font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}
.btn-gray-rounded {
  @apply inline-flex items-center rounded-full border border-transparent bg-gray-500  font-medium text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}
.btn-gray-light {
  @apply inline-flex items-center rounded-md border border-transparent bg-gray-400  font-medium text-gray-900 shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}

.btn-white {
  @apply inline-flex items-center rounded-md border border-gray-300 bg-white font-medium text-gray-700 shadow-sm hover:bg-gray-50 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}

.btn-white-rounded {
  @apply inline-flex items-center rounded-full border border-gray-300 bg-white font-medium text-gray-700 shadow-sm hover:bg-gray-50 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}

.btn-white-square {
  @apply inline-flex items-center border border-gray-300 bg-white font-medium text-gray-700 shadow-sm hover:bg-gray-50 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}
.btn-red-outline {
  @apply inline-flex items-center rounded-md border border-red-300 bg-transparent font-medium text-red-600 hover:bg-red-50 hover:border-red-400 hover:brightness-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2;
}

.btn-red-outline-rounded {
  @apply inline-flex items-center rounded-full border border-red-300 bg-transparent font-medium text-red-600 hover:bg-red-50 hover:border-red-400 hover:brightness-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2;
}

.btn-gray-outline {
  @apply inline-flex items-center rounded-md border border-gray-300 bg-transparent font-medium text-gray-600 hover:bg-gray-200 hover:border-gray-400 hover:brightness-105 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}
.btn-gray-outline-rounded {
  @apply inline-flex items-center rounded-full border border-gray-300 bg-transparent font-medium text-gray-600 hover:bg-gray-200 hover:border-gray-400 hover:brightness-105 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}

.btn-danger {
  @apply inline-flex items-center rounded-full border border-transparent bg-red-600  font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2;
}
.btn-danger-clear-rounded {
  @apply p-2 inline-flex items-center rounded-full border border-transparent  font-medium  shadow-sm hover:bg-red-100 text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2;
}

.btn-danger-outline {
  @apply inline-flex items-center rounded-md border border-red-300 bg-transparent font-medium text-red-600 hover:bg-red-200 hover:border-red-400 hover:brightness-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2;
}
.btn-danger-outline-rounded {
  @apply inline-flex items-center rounded-full border border-red-300 bg-transparent font-medium text-red-600 hover:bg-red-200 hover:border-red-400 hover:brightness-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2;
}

.btn-gray-clear-rounded {
  @apply p-2 inline-flex items-center rounded-full border border-transparent  font-medium  shadow-sm hover:bg-gray-100 text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2;
}

/* size */
.btn-sm {
  @apply px-2.5 py-1 text-xs;
}
.btn-md {
  @apply px-3 py-1 text-sm;
}
.btn-lg {
  @apply px-4 py-1.5 text-sm;
}
.btn-xl {
  @apply px-4 py-1.5 text-base;
}
.btn-2xl {
  @apply px-6 py-2 text-base;
}
.btn-3xl {
  @apply px-6 sm:px-8 py-2 sm:py-3 text-base;
}
.btn-4xl {
  @apply px-8 sm:px-10 py-3 sm:py-4 text-xl;
}

.btn-square-sm {
  @apply p-2  text-xs;
}
.btn-square-md {
  @apply p-2 text-sm;
}
.btn-square-lg {
  @apply p-2.5 text-sm;
}
.btn-square-xl {
  @apply p-3 text-base;
}
.btn-square-2xl {
  @apply p-4 text-base;
}
.btn-square-3xl {
  @apply p-6 text-base;
}
.btn-square-4xl {
  @apply p-8 text-xl;
}
/* Hover */

.butn-hover-xl {
  @apply shadow-sm hover:shadow-xl hover:brightness-105 transition delay-100 ease-in;
}

/* BUTTON COLORS */
.butn-primary {
  @apply bg-c-teal-700  border-2  border-c-teal-700 text-white;
}

.butn-primary-light {
  @apply bg-c-teal-500  border-2  border-c-teal-500 text-white;
}

.butn-c-teal-900 {
  @apply bg-c-teal-900 text-white;
}

.butn-c-teal-900-outline {
  @apply bg-c-teal-900 text-c-teal-900 bg-transparent border-2 border-c-teal-900;
}

.butn-disabled {
  @apply opacity-80;
}

.butn-primary-outline {
  @apply bg-white border-2 rounded border-c-teal-700 text-c-teal-700;
}

.butn-white {
  @apply bg-white text-gray-700;
}

.butn-inverse {
  @apply bg-white text-c-teal-500;
}

.butn-inverse-orange-abundo {
  @apply bg-white text-c-orange-abundo;
}

.butn-inverse-orange-abundo:hover {
  @apply border-c-orange-abundo;
}

.butn-orange-abundo {
  @apply bg-c-orange-abundo text-white;
}

.butn-outline-orange-abundo {
  @apply text-c-orange-abundo bg-transparent border-2 border-c-orange-abundo rounded;
}

.butn-outline-orange-abundo:hover {
  @apply bg-c-orange-abundo text-white;
}

.butn-link {
  @apply text-c-hyperlink underline;
}

/* TEXT ------------------------------------------------------------------------------ */

h1,
.text-h1 {
  @apply leading-10 lato text-3xl sm:text-5xl font-bold font-sans tracking-wide;
}

h2,
.text-h2 {
  @apply text-2xl lato sm:text-3xl xl:text-4xl font-black font-sans leading-tight tracking-wider;
}

h3,
.text-h3 {
  @apply text-lg lato sm:text-lg lg:text-2xl font-bold font-sans tracking-wide;
}

h4,
.text-h4 {
  @apply text-base lato sm:text-xl font-bold font-sans tracking-wide;
}

h5,
.text-h5 {
  @apply text-base lato sm:text-lg xl:text-2xl font-sans;
}

h6,
.text-h6 {
  @apply text-base lato  xl:text-xl font-sans;
}

/* p,
.text-p {
  @apply leading-tight;
} */

/* ANNIMATIONS -------------------------------------------------------------------------- */
/* CSS Loading Spinner 1 */
.spinner-sector {
  animation: rotate var(--duration) var(--timing) infinite;
  pointer-events: none;
}
.spinner-section-1 {
  --duration: 2s;
  --timing: ease-in;
}
.spinner-section-2 {
  --duration: 2.5s;
  --timing: ease-in;
}
.spinner-section-3 {
  --duration: 1.5s;
  --timing: ease-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* END ANNIMATIONS -------------------------------------------------------------------------- */

/* TABLES ------------------------------------------------------------------------------- */

/* Table Primary */
.table-primary {
  @apply lg:rounded-xl my-10 lg:divide-y divide-gray-200  lg:shadow-lg;
}

.table-primary th {
  @apply px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider bg-c-orange-abundo;
}

.table-primary tr {
  @apply text-center hover:shadow-md transition duration-100 ease-in;
}

.table-primary td {
  @apply px-6 py-4 text-sm font-medium text-gray-900;
}

.table-primary tbody {
  @apply bg-white divide-y divide-gray-200;
}

@media (max-width: 1024px) {
  .table-primary thead {
    display: none;
  }

  .table-primary,
  .table-primary tbody,
  .table-primary tfoot,
  .table-primary tr,
  .table-primary td {
    @apply block w-full;
  }

  .table-primary tbody {
    @apply space-y-10 bg-transparent  min-w-max;
  }
  .table-primary tr {
    @apply text-center transition duration-100 ease-in bg-white shadow-md rounded-lg my-2 divide-y lg:divide-none divide-c-orange-abundo px-2 min-w-full;
  }

  .table-primary tbody tr td {
    position: relative;
    @apply py-3 px-5 lg:px-0 flex flex-col justify-between items-center   xs:flex-row  xs:justify-between;
  }

  .table-primary td:before {
    content: attr(data-label);
    @apply font-bold  xs:pr-5 text-center xs:text-left;
  }
}

/* Table Variants */

/* Table Primary Striped */
.table-dividers > tbody > tr {
  @apply lg:first:border-0 lg:border-t lg:border-c-orange-400;
}

.table-dividers > tbody > tr:hover {
  @apply lg:bg-orange-100 transition delay-75 ease-out shadow-md;
}

/* Modals -------------------------------------------------------------------- */
/* Modal Large */

.modal-lg {
  @apply overflow-y-auto  px-4 lg:px-16 py-12 rounded border w-full flex flex-col justify-center items-center max-w-screen-md;
  max-height: 90vh;
}

.modal-lg div {
  max-height: 90vh;
}

/* FORMS ------------------------------------------------------------------------------- */

/* Form Primary */

.form-primary {
  @apply max-w-screen-lg w-full space-y-10 sm:px-10;
}

.form-primary label {
  @apply mb-3 text-c-orange-abundo text-sm sm:text-lg lg:text-xl font-semibold tracking-wider max-w-full w-11/12  xs:w-full;
}

.form-primary input {
  @apply px-1 xs:px-8 py-5 text-lg lg:text-2xl bg-white outline-none rounded  w-11/12 xs:w-full;
}

.form-primary input[type="checkbox"] {
  @apply w-5 h-5 p-0 outline-none rounded-md focus:border-0 focus:p-0;
}

.form-primary input:focus {
  @apply outline-none border-2 border-c-orange-abundo;
}

.form-primary textarea {
  @apply px-1 xs:px-8 py-5 text-sm lg:text-2xl bg-white outline-none rounded  w-11/12 xs:w-full;
}

.form-primary textarea:focus {
  @apply outline-none border-2 border-c-orange-abundo;
}

.form-primary select {
  @apply px-1 xs:px-8 py-5 text-sm sm:text-2xl bg-white outline-none rounded w-11/12 xs:w-full text-gray-600;
}

.form-primary div.mobile-row {
  @apply flex flex-col items-center xs:items-start;
}

/* Form Auth */

.form-auth {
  @apply space-y-7;
}
.form-auth input[type="checkbox"] {
  @apply w-5 h-5 p-0 outline-none rounded-md focus:border-0 focus:p-0;
}

.form-auth label {
  @apply mb-3 text-c-orange-abundo text-sm sm:text-xl lg:text-2xl font-bold tracking-wider max-w-full w-11/12  xs:w-full;
}

.form-auth input {
  @apply px-2 xs:px-3 py-5 text-xl lg:text-2xl bg-white border  outline-none rounded  w-full;
}

.form-auth input:focus {
  @apply outline-none border-2 border-c-orange-abundo;
}

.form-auth select {
  @apply px-2 xs:px-3 py-5 text-sm sm:text-2xl bg-white outline-none rounded w-11/12 xs:w-full;
}

.form-auth div.mobile-row {
  @apply flex flex-col items-center xs:items-start;
}

/* Form Table */

.form-auth label {
  @apply mb-3 text-c-teal-700 text-sm sm:text-xl lg:text-2xl font-bold tracking-wider max-w-full w-11/12  xs:w-full;
}

.form-table input {
  @apply py-2 px-4 rounded border border-gray-300 text-center;
}

.form-table input:focus {
  @apply outline-none border-2 border-c-orange-abundo;
}

.form-table select {
  @apply px-2 xs:px-3 py-5 text-sm sm:text-2xl bg-white outline-none rounded w-11/12 xs:w-full;
}

/* DropDown ---------------------------------------------------- */

.dropdown-primary li {
  @apply px-2 py-1  rounded text-lg text-gray-900 transition ease-in-out duration-500;
}

.dropdown-primary li:hover {
  @apply bg-orange-100;
}

/* Inputs ----------------------------------------------------------------------------------------------- */

/* Numbers, Text ----------------------------------------------*/

/*  Input Number, Text Types --------------------- */

.input-primary {
  @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  border-gray-300 rounded-md;
}
.checkbox-primary {
  @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block h-4 w-4 sm:text-sm  border-gray-300 rounded-md;
}
.checkbox-primary-lg {
  @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block h-6 w-6 sm:text-sm  border-gray-300 rounded-md;
}
.label-primary {
  @apply block text-sm font-medium text-gray-700 mb-1;
}

/* rounded */

.input-rounded {
  @apply bg-white outline-none rounded;
}

/* colors */
.input-white {
  @apply bg-white text-gray-800;
}

/* sizes */
.input-md {
  @apply px-1 xs:px-4 py-5 text-base sm:text-xl md:text-2xl lg:text-3xl;
}

/* Type Sliders --------------------------------------------- */

/* -----Slider Types-------- */

/* rounded */
.slider-rounded {
  -webkit-appearance: none;
  @apply rounded-full;
}

.slider-rounded::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply rounded-full cursor-pointer;
}

.slider-rounded::-moz-range-thumb {
  @apply rounded-full cursor-pointer;
}

/* -----Slider Sizes----- */

/* md */
.slider-md {
  @apply h-5;
}

.slider-md::-webkit-slider-thumb {
  @apply h-10 w-10;
}

.slider-md::-moz-range-thumb {
  @apply h-10 w-10;
}

.slider-md::-webkit-range-track {
  @apply h-5 bg-c-orange-400;
}

/* xl */
.slider-xl {
  @apply h-10;
}

.slider-xl::-webkit-slider-thumb {
  @apply h-20 w-20;
}

.slider-xl::-moz-range-thumb {
  @apply h-20 w-20;
}

.slider-xl::-webkit-range-track {
  @apply h-5 bg-c-orange-400;
}

/*---Slider Colors------- */

/*  Orange Abundo */
.slider-lagoon-light {
  @apply bg-c-lagoon-light;
}

.slider-lagoon-light::-webkit-slider-thumb {
  @apply bg-white shadow-lg h-7 w-7;
}

.slider-lagoon-light::-moz-range-thumb {
  @apply bg-white;
}

.slider-ticks-1 p {
  @apply text-c-lagoon-light;
}

/*------ Slider Ticks ------ */
.slider-label {
  @apply flex justify-between pt-2 px-1;
}

.slider-label p {
  @apply relative flex justify-center text-center w-px h-3;
}

/** ------- ToolTips ----------- */
.tooltip::before {
  @apply bg-gray-800 bg-opacity-30  p-10 rounded text-white text-sm hidden;
  content: attr(title);
}

.tooltip::before:hover {
  @apply block bg-gray-800 bg-opacity-30  p-10 rounded text-white text-sm;
}

/**  ---------- information ---------  */

.section {
  @apply mx-5 max-w-screen-2xl;
}

.subsection {
  @apply space-y-4;
}

.section-title {
  @apply text-c-orange-abundo mb-7 ml-7 tracking-wider;
}

.section ul {
  @apply px-5 lg:px-10 list-disc  space-y-1 leading-tight;
}

.section-container {
  @apply bg-white text-gray-800 p-8 lg:p-12 rounded space-y-8;
}

.thumbnail {
  @apply h-60 w-96;
}
